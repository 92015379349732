<template>
  <div @dragenter.prevent="toggle_active()" @dragleave.prevent="toggle_active()" @dragover.prevent @drop.prevent="drop"
       :class="{ 'bg-green-100 border-green-300 dark:bg-green-700': dragActive }"
       class="flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
    <div class="flex flex-col items-center justify-center gap-2 text-gray-500 dark:text-gray-100 ">
      <span v-if="droppedFile.length===0">
        <svg xmlns="http://www.w3.org/2000/svg" :class="{ 'animate-bounce': droppedFile === null }"
             class="w-20 h-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
        </svg>
      </span>
      <p class="text-base md:text-xl font-semibold" v-if="droppedFile.length===0">Arrastra el archivo acá</p>
      <!-- File input -->
      <label for="file" v-if="droppedFile.length===0">
        <span class="text-purple-500 dark:text-gray-100">Selecciona el archivo</span>
        <input type="file" @change="selectedFile" name="file" id="file" class="d-none"/>
      </label>
      <!-- Dropped file info -->
      <div v-if="droppedFile.length>0"
           class="flex flex-wrap items-center justify-center gap-2 text-base font-semibold text-gray-600 dark:text-gray-100">
        <a class="text-xs" :href="droppedFile" v-if="tipo==='archivo'"><i class="fa fa-file-archive"></i>Archivo</a>
        <img class="img-fluid" height="120px" :src="droppedFile" v-if="tipo==='imagen'">
        <button @click="clearDropped()" class="inline-flex items-center justify-center">
          <i class="fa fa-remove"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import TableService from "@/services/TableService";

export default defineComponent({
  name: 'NewsFormPage',
  props: [
    'campo',
    'ruta',
    'tipo',
    'actualizarDato',
    'defaultValue',
  ],
  data() {
    return {
      dragActive: false,
      droppedFile: this.defaultValue,
    }
  },
  methods: {
    toggle_active() {
      if (this.droppedFile == null) {
        this.dragActive = !this.dragActive;
      }
    },
    drop(event) {
      const vm = this.$data;
      const vmm = this.$props;
      let formData = new FormData();
      formData.append('campo', this.campo);
      formData.append('ruta', this.ruta);
      formData.append(this.campo, event.dataTransfer.files[0]);
      TableService.cargar({tabla: 'noticias', parametros: formData})
          .then(function (response) {
            vmm.actualizarDato(response.data.resultado);
            vm.droppedFile = response.data.resultado;
          })
          .catch(function (error) {
            console.log(error.response, error);
          });
    },
    selectedFile(event) {
      this.droppedFile = event.target.files[0];
      this.dragActive = true;
    },
    clearDropped() {
      this.droppedFile = '';
      this.dragActive = false;
    },
  }
});
</script>