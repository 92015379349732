
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { defineComponent } from 'vue';
import TableService from '@/services/TableService';
import DropZone from '@/components/DropZone.vue';

export default defineComponent({
  name: 'NewsFormPage',
  components: {
    'editor': Editor,
    'drop-zone': DropZone,
  },
  data() {
    return {
      step: 0,
      original: {
        titulo: '',
        avance: '',
        id: null,
        imagen: '',
        imagen2: '',
        fecha: '',
        destacado: false,
        contenido: ''
      },
      message: '',
      error: '',
      sanitized: '',
      sanitizedConfig: {
        //allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p' ],
        allowedTags: [ 'p', 'strong', 'b', 'i', 'em' ],
        transformTags: {
          'ol': 'ul',
        }
      },
      configEditor: {
        toolbar_mode: 'floating',
        plugins: 'link lists powerpaste wordcount',
        toolbar: 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | indent | bullist numlist outdent indent | link unlink | removeformat | wordcount',
        menubar: false,
        language: 'es',
        forced_root_block: 'p',
        height: '75vh',
        force_p_newlines : true
      },
      imagenCampo: {ruta: 'apifie/archivos/imagenes/noticias', campo: 'imagen', tipo: 'imagen'},
      imagen2Campo: {ruta: 'apifie/archivos/imagenes/noticias', campo: 'imagen2', tipo: 'imagen'},
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const vm = this.$data;
      const id = this.$route.params.id+"";
      TableService.visualizar({tabla: 'noticias', tid: id})
          .then(function (response) {
            if(response.data.respuesta == true) {
              if(response.data.resultado.length > 0) {
                vm.original = response.data.resultado[0];
                console.log(response.data.resultado[0]);
              }
            }
          })
          .catch(function (error) {
            console.log(error.response.data);
          });
    },
    cleanUp() {
      if(this.original !== null) {
        this.sanitized = sanitizeHtml(this.original.contenido, this.sanitizedConfig)
      }
    },
    nextStep() {
      if(this.original !== null && this.step===0) {
        console.log(this.sanitized.length, this.original.contenido);
        if(this.sanitized.length>0) {
          this.original.contenido = this.sanitized;
        }
        this.step = 1;
      }
      else if(this.step === 1 ) {
        this.error = '';
        this.message = '';
        if(!this.validateForm()) {
          return ;
        }
        const vm = this.$data;
        const id = this.$route.params.id+"";
        console.log(id);

        const call = id=='undefined'? TableService.almacenar({tabla: 'noticias', parametros: vm.original})
            : TableService.actualizar({tabla: 'noticias', tid: id, parametros: vm.original});

        call.then(function (response) {
          if(response.data.respuesta == true) {
            if(response.data.resultado.length > 0) {
              vm.original = response.data.resultado[0];
              console.log(response.data.resultado[0]);
            }
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
      }
    },
    validateForm() {
      let res = true;
      res = res && this.original.titulo!=null && this.original.titulo.length>0;
      res = res && this.original.avance!=null && this.original.avance.length>0;
      res = res && this.original.imagen!=null && this.original.imagen.length>0;
      res = res && this.original.imagen2!=null && this.original.imagen2.length>0;
      res = res && this.original.contenido!=null && this.original.contenido.length>0;
      res = res && this.original.fecha!=null && moment(this.original.fecha, 'MM/DD/YYYY hh:mm:ss',true).isValid();

      console.log(this.original);

      if(!res) {
        this.error = 'Uno de los campos requeridos no esta siendo llenado';
      }

      return res;
    },
    setImagen(url: string) {
      if(this.original !== null) {
        this.original.imagen = url;
      }
    },
    setImagen2(url: string) {
      if(this.original !== null) {
        this.original.imagen2 = url;
      }
    }
  }
});
