
import { defineComponent } from 'vue';
import axios from "axios";
import TableService from "@/services/TableService";

export default defineComponent({
  name: 'NewsListPage',
  data() {
    return {
      paginate: { data: [] }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const vm = this.$data;
      const tk = window.localStorage.getItem('satellizer_token');
      const dataForm = {
        _token: tk,
        per_page: 10,
        page: 1,
        orden: [
          {
            campo: "id",
            direccion: "desc"
          }
        ],
        comparaciones: [
          {
            campo: "titulo",
            operador: "like",
            dato: "",
            dato2: ""
          }
        ]
      }
      TableService.filtrar({tabla: 'noticias', params: dataForm})
          .then(function (response) {
            if(response.data.respuesta == true) {
              vm.paginate = response.data.resultado;
            }
            console.log(response.data.resultado);
          })
          .catch(function (error) {
            console.log(error.response.data);
          });
    },
    deleteModal() {
      console.log('Data');
    }
  }
});
