import { createWebHistory, createRouter } from "vue-router";

import Homepage from './home/Homepage.vue'
import NewsListPage from "@/pages/news/NewsListPage.vue";
import NewsFormPage from "@/pages/news/NewsFormPage.vue";

const routes = [
  { path: '/', name: 'dashboard', component: Homepage },
  { path: '/noticias', name: 'news', component: NewsListPage },
  { path: '/noticias/crear', name: 'news.create', component: NewsFormPage },
  { path: '/noticias/:id', name: 'news.edit', component: NewsFormPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;