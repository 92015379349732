import axios from "axios";
import ConstantsFIE from "@//ConstantsFIE";

class TableService {
  listar({tabla, page, per_page}: { tabla: string, page: number, per_page: number }) {
    if (page != null && per_page != null) {
      return axios.get(ConstantsFIE.API_URL + '/api/' + tabla + '?page=' + page + '&per_page=' + per_page);
    } else {
      return axios.get(ConstantsFIE.API_URL + '/api/' + tabla);
    }
  }

  filtrar({tabla, params}: { tabla: string, params: any }) {
    if (tabla == 'usuarios') {
      params['token'] = window.localStorage.getItem('satellizer_token');
    }
    return axios.post(ConstantsFIE.API_URL + '/api/' + tabla + '/filtrar', params);
  }

  visualizar({tabla, tid}: { tabla: string, tid: string }) {
    //const params = '?token='+window.localStorage.getItem('satellizer_token')
    return axios.get(ConstantsFIE.API_URL + '/api/' + tabla + '/visualizar/' + tid);
  }

  almacenar({tabla, parametros}: { tabla: string, parametros: any }) {
    parametros['token'] = window.localStorage.getItem('satellizer_token');
    return axios.post(ConstantsFIE.API_URL + '/api/' + tabla + '/almacenar', parametros);
  }

  actualizar({tabla, tid, parametros}: { tabla: string, tid: string, parametros: any }) {
    parametros['token'] = window.localStorage.getItem('satellizer_token');
    return axios.post(ConstantsFIE.API_URL + '/api/' + tabla + '/actualizar/' + tid, parametros);
  }

  cargar({tabla, parametros}: { tabla: string, parametros: FormData }) {
    const tk = window.localStorage.getItem('satellizer_token');
    parametros.append('token', tk+"");
    return axios.post(ConstantsFIE.API_URL + '/api/' + tabla + '/cargar', parametros, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + tk
      }
    });
  }

  eliminar({tabla, tid, parametros}: { tabla: string, tid: string, parametros: any }) {
    if (parametros == null) {
      parametros = {};
    }
    parametros['token'] = window.localStorage.getItem('satellizer_token');
    return axios.post(ConstantsFIE.API_URL + '/api/' + tabla + '/eliminar/' + tid, parametros);
  }

  campos({tabla}: { tabla: string }) {
    const params = '?token='+window.localStorage.getItem('satellizer_token')
    return axios.get(ConstantsFIE.API_URL + '/api/' + tabla + '/campos' + params);
  }

  estructura({tabla}: { tabla: string }) {
    return axios.get(ConstantsFIE.API_URL + '/api/' + tabla + '/estructura');
  }
}

export default new TableService()
