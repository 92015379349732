import { createApp } from 'vue'
import App from "@/App.vue";

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/js/regular.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/regular.css'

import './assets/styles/main.scss'

import router from "@/pages/routes";

createApp(App).use(router)
  .component('Datepicker', Datepicker)
  .mount('#app')
