
import {defineComponent} from 'vue';
import axios from "axios";
import ConstantsFIE from "@/ConstantsFIE";
import FieAppHeader from "@/components/FieAppHeader.vue";
import SideBar from "@/components/SideBar.vue";

export default defineComponent({
  name: 'App',
  components: {SideBar, FieAppHeader},
  mounted() {
    const route = this.$route;
    const params = new URLSearchParams(window.location.search);
    console.log(route.query, window.location.search, params.get('tk'));

    setTimeout(function () {
      const tk = params.get('tk');
      axios.post(ConstantsFIE.API_URL+'/api/session/verify', {ai: tk})
          .then(function (response) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
            window.localStorage.setItem('satellizer_token', response.data.token);
            console.log(response.data.token);
          })
          .catch(function (error) {
            console.log(error.response, tk, 'error');
            let link = document.createElement("a");
            link.href = ConstantsFIE.OLD_ADMIN_URL;
            //link.click();
          });
    }, 300);
  }
});
