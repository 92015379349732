
import sanitizeHtml from 'sanitize-html';
import Editor from '@tinymce/tinymce-vue'
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'Homepage',
  components: {
    'editor': Editor
  },
  data() {
    return {
      original: '',
      sanitized: '',
      config: {
        menubar: false,
        language: 'es'
      }
    }
  },
  methods: {
    cleanUp() {
      this.sanitized = sanitizeHtml(this.original)
    }
  }
})
