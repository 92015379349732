
import { defineComponent } from 'vue';
import ConstantsFIE from "@/ConstantsFIE";

export default defineComponent({
  name: "SideBar",
  data() {
    return {
      old_sys: ConstantsFIE.OLD_ADMIN_URL+'/#/inicio/revision',
    }
  }
});
